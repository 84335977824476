<template>
  <div v-if="selectedSearch" class="vasion-calc-height mainDiv vld-parent">
    <div class="innerDiv">
      <Loading
        class="vasion-loading-indicator"
        :active.sync="isLoading"
        :is-full-page="false"
        :color="loaderColor"
        loader="dots"
        :background-color="loaderBackgroundColor"
      />
      <div class="vasion-page-title lowerLocation">
        {{ searchName }}
      </div>
      <div class="toolbarAlign">
        <div>
          <md-menu
            md-align-trigger
            md-size="huge"
            :mdCloseOnSelect="true"
            :mdCloseOnClick="true"
            md-dense
          >
            <md-button
              id="btnActions"
              md-menu-trigger
              class="smallText vasion-button-text-style"
              :disabled="!areThereCheckedDocuments"
              @click.native="checkIfChecked"
            >
              Actions
            </md-button>
            <md-menu-content class="vasion-menuDivHeight">
              <md-menu-item v-if="canStartWorkflow" @click="startWorkflowMenuClick()">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionWorkflowIcon2 />
                  </div>
                  <div class="vasion-menuText">
                    Start Workflow
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="canLegalHoldDocument" @click="legalHoldDocumentsClick()">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionVaultIcon />
                  </div>
                  <div class="vasion-menuText">
                    Legal Hold
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="canMoveDocument" @click="moveDocumentsClick()">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionArrowRightIcon />
                  </div>
                  <div class="vasion-menuText">
                    Move Documents
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="canCopyDocument" @click="copyDocumentsClick()">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionFileCopyIcon />
                  </div>
                  <div class="vasion-menuText">
                    Copy Documents
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="canEmailDocument" @click="emailDocumentsClick()">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionEmailIcon />
                  </div>
                  <div class="vasion-menuText">
                    Email
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="canDownloadDocument" @click="downloadSelectedDocuments('download')">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionCloudDownloadIcon />
                  </div>
                  <div class="vasion-menuText">
                    Download
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="canDeleteDocument" @click="deleteDocumentsClick()">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionDeleteIcon />
                  </div>
                  <div class="vasion-menuText">
                    Delete
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="canMergeDocuments" @click="showMergeDialog = !showMergeDialog">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionArrowMerge />
                  </div>
                  <div class="vasion-menuText">
                    Merge Documents
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="canPrintDocument" @click="togglePrintDocument()">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionPrintIcon />
                  </div>
                  <div class="vasion-menuText">
                    Print Documents
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="canAccessThumbnailManager" @click="showThumbnailManager = true">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionPhotoGallery />
                  </div>
                  <div class="vasion-menuText">
                    Thumbnail Manager
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="areDocumentsPdfOrTiff" @click="toggleOcr()">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionMediaEditingScanDocumentIcon class="fill-grey-400" />
                  </div>
                  <div class="vasion-menuText">
                    OCR
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="areDocumentsPdfOrTiff" @click="toggleBarcodeRecognition()">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionBarcodeIcon class="fill-grey-400" />
                  </div>
                  <div class="vasion-menuText">
                    Barcode Recognition
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="areDocumentsPdfOrTiffAndOnlyTwoOrThreeDocuments" @click="goToCompareDocuments()">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionColumnViewIcon class="fill-grey-400" />
                  </div>
                  <div class="vasion-menuText">
                    Compare Documents
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="canOCRDocuments" @click="toggleAIP()">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionScheduledAip class="fill-grey-400" />
                  </div>
                  <div class="vasion-menuText">
                    AIP
                  </div>
                </div>
              </md-menu-item>
            </md-menu-content>
          </md-menu>
        </div>
        <div>
          <VasionButton
            id="btnRefresh"
            :isDense="true"
            :icon="'VasionRefreshIcon'"
            title="Refresh"
            @vasionButtonClicked="refreshResults()"
          />
        </div>
        <div class="vasion-toolbar-divider" />
        <div class="nav-controls">
          <div>
            <VasionButton
              id="btnFirstPage"
              :isDense="true"
              :icon="'VasionFirstPageIcon'"
              title="First Page"
              :isDisabled="disablePreviousButtons"
              @vasionButtonClicked="goToFirstPage()"
            />
            <VasionButton
              id="btnPreviousPage"
              :isDense="true"
              :icon="'VasionPreviousPageIcon'"
              title="Previous Page"
              :isDisabled="disablePreviousButtons"
              @vasionButtonClicked="goToPreviousPage()"
            />
          </div>
          <div class="w60 moveUp">
            <md-field>
              <md-input v-model="pageDisplay" class="w60 centerText" readOnly />
            </md-field>
          </div>
          <div>
            <VasionButton
              id="btnNextPage"
              :isDense="true"
              :icon="'VasionNextPageIcon'"
              title="Next Page"
              :isDisabled="disableNextButtons"
              @vasionButtonClicked="goToNextPage()"
            />
            <VasionButton
              id="btnLastPage"
              :isDense="true"
              :icon="'VasionLastPageIcon'"
              title="Last Page"
              :isDisabled="disableNextButtons"
              @vasionButtonClicked="goToLastPage()"
            />
          </div>
        </div>
        <div class="w75">
          <VasionDropList
            v-slot="slotItem"
            v-model="selectedPageSize"
            :dataArray="localPageSizeOptions"
            width="100%"
            type="plain-list"
            displayName="name"
            placeholder=""
          >
            {{ slotItem.item.name }}
          </VasionDropList>
        </div>
        <div class="vasion-toolbar-divider" />
        <div id="toolbar-search-buttons">
          <VasionButton
            v-if="!activeSearch.isGlobalSearch && searchCriteriaHasFields"
            id="refine-search-button"
            class="header-image-button"
            name="refine-search-button"
            title="Refine Search"
            :icon="'VasionEditIcon'"
            :isDense="true"
            @vasionButtonClicked="showRefineSearch()"
          />
          <VasionButton
            v-if="!activeSearch.isGlobalSearch && searchCriteriaHasFields"
            id="resetSearch"
            :isDense="true"
            :icon="'VasionResetSearchIcon'"
            title="Reset Search"
            @vasionButtonClicked="setTypeSearch('reset')"
          />
          <VasionButton
            v-if="searchCriteriaHasFields"
            id="changeSearch"
            :isDense="true"
            :icon="'VasionSwapIcon'"
            title="Change Search"
            @vasionButtonClicked="setTypeSearch('change')"
          />
          <VasionButton
            v-if="showViewHitsButton"
            id="view-hits-search"
            class="header-image-button"
            name="view-hits-search"
            title="View Hits"
            :icon="'VasionPinIcon'"
            :isDisabled="viewHitsDisabled"
            :isDense="true"
            @vasionButtonClicked="viewHitsSearch()"
          />
        </div>
        <div />
      </div>
      <div class="resultsGridDiv">
        <div v-if="displayHeaders.length > 0 && showGrid">
          <VasionTable
            id="search-table"
            :headerColumns="displayHeaders"
            :tableRows="displayRows"
            :hideColumns="hideColumns"
            :supportSorting="true"
            @newList="setCheckedDocuments"
            @vasion-selection="handleRowSelect"
            @vasion-row-checked="handleRowCheckChange"
            @vasion-column-sort="handleColumnSort"
          />
        </div>
        <div v-if="!isLoading && !showGrid">
          No results to display
        </div>
      </div>
    </div>
    <VasionGeneralModal
      :hideButtons="true"
      :rejectButtonText="'Cancel'"
      :confirmButtonText="'Merge'"
      :modalType="'slot'"
      :sync="showMergeDialog"
      @noButtonClick="closeMergeModal"
      @confirmButtonClick="$store.dispatch(&quot;document/setMergeKeyToRunMerge&quot;)"
    >
      <MergeFiles @showSnackbar="showSnackbar" />
    </VasionGeneralModal>

    <div v-if="showActiveSearch" class="overlay" @click.self="toggleActiveSearchDisplay">
      <div id="search-modal" class="modal-container">
        <div class="header-bar">
          <h4 class="search-header">
            {{ activeSearch.Name }}
          </h4>
          <div />
        </div>
        <form class="search-form-container" :class="{'temp-drop-down-class': activeSearch.Type === 161 }">
          <FullTextSearchOptions
            v-if="activeSearch.Type === 161"
            ref="fullTextSearch"
            class="temp-drop-list-styles"
          />
          <IndexSearch
            v-else
            ref="indexSearch"
            :searchID="activeSearch.SearchId"
            :closeSearchDisplay="toggleActiveSearchDisplay"
          />
        </form>
        <div class="button-container">
          <VasionButton classProp="text" :clickActionCallback="clearSearchCriteria">
            CLEAR
          </VasionButton>
          <VasionButton classProp="text" :clickActionCallback="toggleActiveSearchDisplay">
            CANCEL
          </VasionButton>
          <VasionButton classProp="primary" :clickActionCallback="saveSearchCriteria">
            SEARCH
          </VasionButton>
        </div>
      </div>
    </div>

    <md-dialog id="browseFoldersModal" :md-active.sync="showMergeBrowseDialog" @md-clicked-outside="showMergeDialog = true">
      <div class="folderDialogBrowseDiv">
        <VasionFolders
          @itemSelected="handleMergeBrowseDialogSelection"
        />
      </div>
      <div class="alignRight">
        <VasionButton
          id="btnFolderBrowserCancel"
          classProp="secondary"
          :isRaised="false"
          @vasionButtonClicked="toggleMergeBrowse"
        >
          Cancel
        </VasionButton>
        <VasionButton
          id="btnFolderBrowserOK"
          classProp="primary"
          :isRaised="false"
          :isDisabled="!mergeBrowseDialogSelectionMade"
          @vasionButtonClicked="toggleMergeBrowse"
        >
          OK
        </VasionButton>
      </div>
    </md-dialog>

    <md-dialog id="browseFilesModal" :md-active.sync="showMergeAddDialog" @md-clicked-outside="showMergeDialog = true">
      <div class="folderDialogBrowseDiv">
        <VasionFolders
          :showFiles="true"
          @itemSelected="handleMergeAddDocumentSelection"
        />
      </div>
      <div class="alignRight">
        <VasionButton
          id="btnFolderBrowserCancel"
          classProp="secondary"
          :isRaised="false"
          @vasionButtonClicked="toggleMergeAdd"
        >
          Cancel
        </VasionButton>
        <VasionButton
          id="btnFolderBrowserOK"
          classProp="primary"
          :isRaised="false"
          :isDisabled="!mergeAddSelectionMade"
          @vasionButtonClicked="handleMergeAddDocument"
        >
          OK
        </VasionButton>
      </div>
    </md-dialog>

    <md-dialog id="download-document-modal" :md-active.sync="showDownloadModal">
      <VasionDownloadOptions :multipleDocuments="multipleDocuments" @okClick="downloadDocument" @cancelClick="toggleDownloadDocument" />
    </md-dialog>

    <md-dialog id="download-document-modal" :md-active.sync="showPrintModal">
      <VasionPrintOptions @okClick="printSelectedDocuments" @cancelClick="togglePrintDocument" />
    </md-dialog>

    <VasionGeneralModal
      :hideButtons="true"
      :modalType="'slot'"
      :sync="showThumbnailManager"
      @noButtonClick="showThumbnailManager = false"
    >
      <ThumbnailManager v-if="showThumbnailManager" :documentId="checkedDocuments[0]" @cancel="closeThumbnailManager($event)" />
    </VasionGeneralModal>

    <md-dialog id="email-modal" :md-active.sync="showEmailModal" class="email-modal-div">
      <DocumentEmail
        :documentIDString="checkedDocumentsString"
        @email-result="showEmailSnackbar"
        @close="closeDocumentEmail"
      />
    </md-dialog>

    <md-dialog id="move-modal" :md-active.sync="showMoveModal" class="email-modal-div">
      <DocumentMove
        :documentIDString="checkedDocumentsString"
        @move-complete="moveCompleted"
        @close="closeDocumentMove"
      />
    </md-dialog>

    <md-dialog id="copy-modal" :md-active.sync="showCopyModal" class="email-modal-div">
      <DocumentCopy
        :documentIDArray="checkedDocumentsArray()"
        @copy-complete="copyComplete(checkedDocumentsArray())"
        @close="showCopyModal = false"
      />
    </md-dialog>

    <md-dialog id="vasion-confirmation-div" :md-active.sync="showConfirmationDialog">
      <VasionConfirmationDialog :message="confirmationMessage" @noButtonClick="noConfirmationClick()" @yesButtonClick="yesConfirmationClick()" />
    </md-dialog>

    <md-dialog id="start-workflow-dialog" :md-active.sync="showStartWorkflow">
      <Loading
        class="vasion-loading-indicator"
        :active.sync="isWFLoading"
        :is-full-page="false"
        :color="loaderColor"
        loader="dots"
        :background-color="loaderBackgroundColor"
      />
      <VasionSelectWorkflow
        v-if="workflowUIType === 'wfSelection'"
        :workflowList="workflowList"
        @cancelButtonClick="showStartWorkflow = false"
        @continueButtonClick="workflowSelected"
      />

      <VasionUserGroupSelect
        v-if="workflowUIType === 'selectUser'"
        modalTitle="Select User"
        :userOnly="true"
        :groupFilterId="assignUserGroupFilterId"
        @noButtonClick="showStartWorkflow = false"
        @yesButtonClick="workflowSelectUserOKClick"
      />
    </md-dialog>

    <md-dialog id="view-hits-dialog" :md-active.sync="showViewHits">
      <ViewHits :data="viewHitsResponse" @close="showViewHits = !showViewHits" />
    </md-dialog>

    <VasionSnackbar
      id="results-snack"
      :showSnackbarBool.sync="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
    <VasionGeneralModal
      :rejectButtonText="'Cancel'"
      :confirmButtonText="'Run OCR'"
      modalTitle="OCR"
      :modalType="'slot'"
      :sync="showOcrDialog"
      @confirmButtonClick="ocrDocuments"
      @noButtonClick="showOcrDialog = !showOcrDialog"
    >
      <DocumentOcrPages @ocrAllPagesUpdated="updateOcrAllPages" />
    </VasionGeneralModal>

    <VasionGeneralModal
      :rejectButtonText="'Cancel'"
      :confirmButtonText="'Start'"
      modalTitle="Barcode Recognition"
      :modalType="'slot'"
      :sync="showBarcodeDialog"
      @confirmButtonClick="sendDocBarcodeRecognition"
      @noButtonClick="showBarcodeDialog = !showBarcodeDialog"
    >
      <DocumentBarcodeRecognition ref="barcodeRecognition" />
    </VasionGeneralModal>

    <VasionGeneralModal
      :rejectButtonText="'Cancel'"
      :confirmButtonText="'Run AIP'"
      modalTitle="Advanced Image Processing"
      :modalType="'slot'"
      :showTopRightCloseButton="true"
      :sync="showAIPDialog"
      @confirmButtonClick="runAIP"
      @noButtonClick="showAIPDialog = false"
    >
      <DocumentAIP ref="aip" />
    </VasionGeneralModal>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';

import DocumentAIP from '@/components/document/DocumentAIP.vue';
import DocumentBarcodeRecognition from '@/components/document/DocumentBarcodeRecognition.vue';
import DocumentCopy from '@/components/document/DocumentCopy.vue';
import DocumentEmail from '@/components/document/DocumentEmail.vue';
import DocumentMove from '@/components/document/DocumentMove.vue';
import DocumentOcrPages from '@/components/document/DocumentOcrPages.vue';
import FullTextSearchOptions from '@/components/search/FullTextSearchOptions.vue';
import IndexSearch from '@/components/search/IndexSearch.vue'
import MergeFiles from '@/components/document/MergeFiles.vue';
import ThumbnailManager from '@/components/document/ThumbnailManager.vue';
import ViewHits from '@/components/search/ViewHits.vue'

import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import { removeIllegalChars, testDownloadHasFileExtension } from '@/store/helperModules/common.module'

export default {
  name: 'SearchResults',
  components: {
    DocumentAIP,
    DocumentBarcodeRecognition,
    DocumentCopy,
    DocumentEmail,
    DocumentMove,
    DocumentOcrPages,
    FullTextSearchOptions,
    IndexSearch,
    Loading,
    MergeFiles,
    ThumbnailManager,
    ViewHits,
  },
  data: function () {
    return {
      assignUserGroupFilterId: 0,
      canCopyDocumentCurrentFolder: false,
      checkedDocuments: [],
      checkedDocumentsAreTiffOrPDF: false,
      checkedDocumentsForViewHits: [],
      confirmationMessage: '',
      confirmationType: '',
      currentPageNumber: 1,
      hideColumns: ['_DocumentID_', 'Version ID'],
      isLoading: false,
      isWFLoading: false,
      loaderBackgroundColor,
      loaderColor,
      localPageSizeOptions: [],
      mergeAddSelectionMade: false,
      mergeBrowseDialogSelectionMade: false,
      multipleDocuments: false,
      ocrAllPages: false,
      refreshRowsOnly: false,
      searchName: '',
      searchResults: {},
      selectedPageSize: {
        name: '100',
        value: 100,
      },
      selectedWorkflowID: 0,
      showActiveSearch: false,
      showAIPDialog: false,
      showBarcodeDialog: false,
      showConfirmationDialog: false,
      showCopyModal: false,
      showDownloadModal: false,
      showEmailModal: false,
      showMergeAddDialog: false,
      showMergeBrowseDialog: false,
      showMergeDialog: false,
      showMoveModal: false,
      showOcrDialog: false,
      showPrintModal: false,
      showSnackbarBool: false,
      showStartWorkflow: false,
      showThumbnailManager: false,
      showViewHits: false,
      showViewHitsButton: true,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      totalRecords: 0,
      userAssignPayload: {},
      viewHitsResponse: {},
      workflowList: [],
      workflowUIType: 'wfSelection',
    }
  },
  computed: {
    activeFullTextSearchData() { return this.$store.state.search.searchCriteria },
    activeIndexSearchData() { return this.$store.state.search.indexSearchFields },
    activeSearch() { return this.$store.state.search.selectedSearch },
    areDocumentsPdfOrTiff() { return this.checkedDocumentsAreTiffOrPDF },
    areDocumentsPdfOrTiffAndOnlyTwoOrThreeDocuments() {
      return this.checkedDocumentsAreTiffOrPDF
      && (this.checkedDocuments.length === 2 || this.checkedDocuments.length === 3)
    },
    areThereCheckedDocuments() { return this.checkedDocuments && this.checkedDocuments.length > 0 },
    areThereMoreThenOneCheckedDocuments() { return this.checkedDocuments && this.checkedDocuments.length > 1 },
    canAccessMaxxSafe() { return this.$store.getters['common/canAccessMaxxSafe'] },
    canAccessOcr() { return this.checkedDocumentsAreTiffOrPDF },
    canAccessThumbnailManager() {
      return this.$store.getters['common/canAccessThumbnailManager']
        && !this.areThereMoreThenOneCheckedDocuments
        && this.checkedDocumentsAreTiffOrPDF
    },
    canCopyDocument() { return this.$store.getters['common/canCopyDocument'] && this.canCopyDocumentCurrentFolder },
    canDeleteDocument() { return this.$store.getters['common/canDeleteDocument'] },
    canDownloadDocument() { return this.$store.getters['common/canDownloadDocument'] },
    canEmailDocument() { return this.$store.getters['common/canEmailDocument'] },
    canLegalHoldDocument() { return this.$store.getters['common/canLegalHold'] },
    canMergeDocuments() { return this.$store.getters['common/canMergeDocuments'] && this.areThereMoreThenOneCheckedDocuments },
    canMoveDocument() { return this.$store.getters['common/canMoveDocument'] },
    canOCRDocuments() { return this.$store.getters['common/canOCRDocuments'] },
    canPrintDocument() { return this.$store.getters['common/canPrintDocument'] },
    canStartWorkflow() { return this.$store.getters['common/canAssignToWorkflow'] },
    checkedDocumentsString() { return !this.checkedDocuments || this.checkedDocuments.length === 0 ? '' : this.checkedDocuments.join(',') },
    disableNextButtons() { return this.currentPageNumber >= this.totalPages },
    disablePreviousButtons() { return this.currentPageNumber <= 1 },
    displayHeaders() {
      if (!this.searchResults || !this.searchResults.tableHeaders) {
        return []
      }
      return this.searchResults.tableHeaders
    },
    displayRows() {
      if (!this.searchResults || !this.searchResults.tableRows) {
        return []
      }

      const startIndex = this.pageSize * (this.currentPageNumber - 1)
      const endIndex = this.pageSize * this.currentPageNumber
      return this.searchResults.tableRows.slice(startIndex, endIndex)
    },
    pageDisplay: {
      get: function () {
          return `${this.currentPageNumber}/${this.totalPages}`
      },
      set: function () {
      },
    },
    pageSize: {
      get: function () {
        return this.$store.state.common.pageSize
      },
      set: function (newSize) {
        this.$store.dispatch('common/setPageSize', newSize)
      },
    },
    pageSizeOptions() { return this.$store.state.common.pageSizeOptions },
    searchCriteria() { return this.$store.state.search.searchCriteria },
    searchCriteriaHasFields() { return this.$store.state.search.searchCriteria?.Fields?.length > 0 },
    searchGUID() { return this.$store.state.search.searchGUID },
    selectedSearch() { return this.$store.state.search.selectedSearch },
    searchResultsFromStore() { return this.$store.state.search.searchResults },
    showGrid() { return this.searchResults && this.totalRecords > 0 },
    totalPages() {
      if (this.totalRecords <= 0) {
        return 1
      }

      let ret = Math.floor(this.totalRecords / this.pageSize);
      if (this.totalRecords % this.pageSize !== 0) {
        ret += 1
      }

      return ret
    },
    userID() { return this.$store.state.common.userID },
    viewHitsDisabled() { return this.checkedDocumentsForViewHits.length !== 1 },
  },
  watch: {
    pageSize: function () { this.goToFirstPage() },
    searchCriteria: function () {
      if (this.searchCriteria) {
        this.refreshResults()
      }
    },
    searchResultsFromStore: function () {
      if (this.searchResultsFromStore) {
        this.refreshResults(this.searchResultsFromStore)
      }
    },
    selectedPageSize: {
      handler: function () {
        if (this.selectedPageSize && this.selectedPageSize.name) {
          this.pageSize = this.selectedPageSize.value
        }
      },
      deep: true, // if .name changes on pages size, then we'll pick it up
    },
  },
  created() {
    this.localPageSizeOptions = this.pageSizeOptions.map((element) => {
      return {
        name: element.toString(),
        value: element,
      }
    })

    const localPageSize = this.pageSize && this.pageSize > 0 ? this.pageSize : 100

    setTimeout(() => {
      this.selectedPageSize = this.localPageSizeOptions.find((option) => {
        return option.value === localPageSize
      })
    }, 100)

    this.refreshResults(this.$route.params?.loadResultsFromStore)
  },
  methods: {
    actionSelected(action) {
      switch (action) {
        case 'print':
          this.printDocuments()
          break
        case 'download':
          this.downloadDocument()
          break
        default:
          break
      }
    },
    checkedDocumentsArray() { return !this.checkedDocuments || this.checkedDocuments.length === 0 ? '' : Object.values(this.checkedDocuments) },
    checkIfChecked() {
      this.updateCheckedDocumentsAreTiffOrPDF()
      return this.areThereCheckedDocuments ? null : this.showSelectionSnackbar()
    },
    async clearSearchCriteria() {
      if (this.activeSearch.Type === 161) {
        this.$refs.fullTextSearch.clear()
      } else {
        this.$refs.indexSearch.clear()
      }
    },
    closeDocumentEmail() { this.showEmailModal = false },
    closeDocumentMove() { this.showMoveModal = false },
    closeMergeModal() {
      this.showMergeDialog = false;
       this.$store.dispatch('document/setMergeClearValues')
    },
    closeThumbnailManager(event) {
      if (event) {
        if (event.Value !== '') {
          this.showSnackbarBool = true
          this.snackbarImage = false
          this.snackbarSubTitle = event.Value
          this.snackbarTitle = 'Error'
        } else {
          this.showSnackbarBool = true
          this.snackbarImage = true
          this.snackbarTitle = 'Saved'
          this.snackbarSubTitle = ''
        }
      }
      this.showThumbnailManager = false
    },
    copyComplete(copyResultMessage) {
      this.snackbarTitle = copyResultMessage.length > 0 ? 'Document(s) successfully copied!' : 'Unable to copy one or more Documents'
      this.snackbarImage = copyResultMessage.length > 0
      this.showSnackbarBool = true
      this.showCopyModal = false
    },
    copyDocumentsClick() {
      if (this.checkedDocumentsString === '') {
        return
      }

      this.showCopyModal = true
    },
    deleteDocumentsClick() {
      if (this.checkedDocumentsString === '') {
        return
      }

      this.confirmationMessage = 'Are you sure you want to delete the selected Document(s)?'
      this.confirmationType = 'Delete'
      this.showConfirmationDialog = true
    },
    async deleteSelectedDocuments() {
      this.isLoading = true
      const errorMessage = await this.$store.dispatch('document/deleteMultipleDocuments', this.checkedDocumentsString)
      const deleteSuccess = errorMessage === ''

      if (deleteSuccess) {
        this.snackbarTitle = 'Documents Deleted Successfully'
        this.snackbarSubTitle = 'The Documents have been removed'
        this.snackbarImage = true
      } else {
        this.snackbarTitle = 'Error Deleting Documents'
        this.snackbarSubTitle = errorMessage
        this.snackbarImage = false
      }

      this.isLoading = false
      this.showSnackbarBool = true;

      // we are refreshing on success or not - if user tried to delete multiple documents, one might have failed, but the rest were deleted
      this.refreshResults()
    },
    async downloadDocument(options) {
      const downloadPayload = {
        T: this.checkedDocumentsString,
        BurnAnnotations: options.burnAnnotations,
        UserID: this.userID,
        IncludeWatermark: false,
        ExtractPages: options.extractPages,
        ConvertToPDF: options.convertToPDF,
        StreamPDF: '',
      }
      const downloadResult = await this.$store.dispatch('document/downloadVasionDocument', downloadPayload)
      if (downloadResult) {
        const fileData = await this.$store.dispatch('document/getDocumentDetails', { documentID: this.checkedDocuments[0] })
        const newFileName = await removeIllegalChars(fileData.DisplayName)

        // eslint-disable-next-line
        const fileDownload = require('js-file-download')
        let localFileExtension = ''
        if (this.multipleDocuments) {
          localFileExtension = '.zip'
          fileDownload(downloadResult, `VasionDownload${localFileExtension}`)
        } else if (downloadPayload.ConvertToPDF === true) {
          localFileExtension = '.pdf'
          fileDownload(downloadResult, `${newFileName}${localFileExtension}`)
        } else {
          const docInfoPayload = {
            DocumentId: this.checkedDocuments[0],
            IncludeFileInfo: false,
            IncludeIndexForm: false,
            IncludeNotes: false,
            IncludeWorkflow: false,
            IncludeLinks: false,
            IncludeVersions: false,
          }

          const docInfoResult = await this.$store.dispatch('document/getDocumentDetailsWithPayload', docInfoPayload)
          if (docInfoResult && docInfoResult.DocumentName && docInfoResult.DocumentName.includes('.')) {
            localFileExtension = docInfoResult.DocumentName.substring(docInfoResult.DocumentName.lastIndexOf('.'))
          }

          testDownloadHasFileExtension(downloadResult, newFileName, localFileExtension)
        }
      } else {
        this.snackbarTitle = 'Error Downloading Document(s)'
        this.snackbarSubTitle = 'Unable to download the selected Document(s)'
        this.snackbarImage = 'error-24-px.png';

        this.showSnackbarBool = true;
      }

      this.showDownloadModal = false
    },
    downloadSelectedDocuments() {
      this.multipleDocuments = false
      if (this.checkedDocuments.length < 1) {
        return
      }
      if (this.checkedDocuments.length > 1) {
        this.multipleDocuments = true
      }
      this.showDownloadModal = true
    },
    emailDocumentsClick() {
      if (this.checkedDocumentsString === '') {
        return
      }

      this.showEmailModal = true
    },
    goToCompareDocuments() {
      this.$store.dispatch('document/setBackRoute', '/searchResults/true')
      this.$router.push({ name: 'TheCompareDocuments', params: { documentIDs: this.checkedDocuments } })
    },
    goToFirstPage() { this.currentPageNumber = 1 },
    goToLastPage() { this.currentPageNumber = this.totalPages },
    goToNextPage() {
      if (this.currentPageNumber < this.totalPages) {
        this.currentPageNumber += 1
      }
    },
    goToPreviousPage() {
      if (this.currentPageNumber > 1) {
        this.currentPageNumber -= 1
      }
    },
    async handleColumnSort(sortData) {
      if (!sortData || !sortData.columnName) {
        return
      }
      this.isLoading = true
      const payload = this.searchCriteria
      payload.OrderBy = sortData.columnName
      payload.Ascending = sortData.ascending

      await this.$store.dispatch('search/setSearchCriteria', payload)
      this.refreshRowsOnly = true
      await this.refreshResults()
      this.isLoading = false
      this.refreshRowsOnly = false
    },
    handleMergeAddDocument() {},
    handleMergeAddDocumentSelection() {},
    handleMergeBrowseDialogSelection() {},
    handleRowCheckChange(checkedRows) {
      this.checkedDocuments = checkedRows.map(item => {
        return item.Values[0]
      })
      this.checkedDocumentsForViewHits = checkedRows.map(item => {
        return {
          id: item.Values[0],
          vNumber: item.Values[6],
        }
      })
    },
    handleRowSelect(selectInfo) {
      if (!selectInfo.Values || selectInfo.Values.length <= 0 || selectInfo.Values[0] <= 0) {
        return
      }

      this.$store.dispatch('document/setBackRoute', '/searchResults/true')
      this.$router.push({ name: 'Document', params: { documentID: selectInfo.Values[0].toString() } })
    },
    legalHoldDocumentsClick() {
      if (this.checkedDocumentsString === '') {
        return
      }

      this.confirmationMessage = 'Are you sure you want to place a legal hold on the selected Document(s)?'
      this.confirmationType = 'LegalHold'
      this.showConfirmationDialog = true
    },
    async legalHoldSelectedDocuments() {
      const errorMessage = await this.$store.dispatch('document/legalHoldDocuments', this.checkedDocumentsString)
      const deleteSuccess = errorMessage === ''

      if (deleteSuccess) {
        this.snackbarTitle = 'Documents legally held successfully'
        this.snackbarSubTitle = 'The Documents have been legally held'
        this.snackbarImage = 'check-circle-24-px.png';
      } else {
        this.snackbarTitle = 'Error legally holding Documents'
        this.snackbarSubTitle = errorMessage
        this.snackbarImage = 'error-24-px.png';
      }

      this.showSnackbarBool = true;

      this.refreshResults()
    },
    moveCompleted(moveResultMessage) {
      this.snackbarTitle = !moveResultMessage ? 'Document(s) successfully moved!' : 'Unable to move one or more Documents'
      this.snackbarSubTitle = moveResultMessage
      this.snackbarImage = moveResultMessage
      this.showSnackbarBool = true
      this.showMoveModal = false
      this.refreshResults()
    },
    moveDocumentsClick() {
      if (this.checkedDocumentsString === '') {
        return
      }

      this.showMoveModal = true
    },
    noConfirmationClick() { this.showConfirmationDialog = false },
    async ocrDocuments() {
      const payload = {
        documentIDs: this.checkedDocuments,
        ocrAllPages: this.ocrAllPages,
      }

      const response = await this.$store.dispatch('document/saveDocumentsForOCR', payload)
      this.toggleOcr()
      this.checkedDocuments = []

      if (response?.Value === 'True') {
        this.showSnackbarBool = true
        this.snackbarTitle = 'Success'
        this.snackbarSubTitle = 'An email will be sent when the documents have been OCRed.'
        this.snackbarImage = true
      } else {
         this.showSnackbarBool = true
        this.snackbarTitle = 'Error'
        this.message = 'Something went wrong, please contact admin'
        this.snackbarImage = false
      }
      this.refreshResults()
    },
    async printSelectedDocuments(settings) {
      this.togglePrintDocument()
      const payload = {
        T: this.checkedDocuments.join(', '),
        BurnAnnotations: settings.burnAnnotations,
        ConvertToPDF: true,
        UserID: this.userID,
      }
      const documentsForPrint = await this.$store.dispatch('workflow/downloadVasionDocumentsForPrint', payload)
      // eslint-disable-next-line
      const printJS = require('print-js')
      if (documentsForPrint) {
        printJS({
          printable: documentsForPrint.Value, type: 'pdf', showModal: true, onPrintDialogClose: () => { this.$store.dispatch('workflow/deletePrintedFile', documentsForPrint) },
        });
      }
    },
    async refreshResults(loadResultsFromStore) {
      this.isLoading = true
      this.checkedDocuments = []
      if (!this.selectedSearch) {
        this.isLoading = false
        return
      }
      let data = {}
      let result = {}
      if (!loadResultsFromStore) {
        result = await this.$store.dispatch('search/runCurrentSearch')
        if (result?.searchGUID && result?.searchResult) {
          if (this.searchGUID) {
            this.$store.dispatch('search/deleteFTSearchDataCache', this.searchGUID)
          }
          this.$store.dispatch('search/setSearchGUID', result.searchGUID)
          this.showViewHitsButton = true
          data = result.searchResult
        } else {
          this.showViewHitsButton = false
          data = result
        }
      } else {
        data = this.searchResultsFromStore
      }
      if (!data || !data.Count || !data.Columns || !data.Columns.Values) {
        this.isLoading = false
        this.totalRecords = 0
        this.searchResults = {}
        return
      }
      if (!this.refreshRowsOnly) {
        this.currentPageNumber = 1
        this.totalRecords = 0
        this.searchName = ''
        this.totalRecords = data.Count
        this.searchResults = {}
        this.searchName = this.selectedSearch.Name ? this.selectedSearch.Name : ''

        this.searchResults = {
          tableHeaders: ['_DocumentID_', '_VasionCheckBox_', '_VasionIcon_'],
          tableRows: [],
        }

        data.Columns.Values.forEach((column) => {
          if (column === 'DisplayName') {
            this.searchResults.tableHeaders.push('Display Name')
          } else {
            this.searchResults.tableHeaders.push(column)
          }
        })
      }
      this.$store.dispatch('search/setSearchResults', data)
      this.refreshTableRows(data)
      this.isLoading = false
    },
    refreshTableRows(data) {
      if (data.Rows && data.Rows.Values) {
        this.searchResults.tableRows = []

        data.Rows.Values.forEach((rowElement) => {
          const dataRow = [rowElement.DocumentId,
          {
            show: true,
            value: false,
          },
          'VasionInsertDriveFileIcon']

          rowElement.Columns.Values.forEach((subElement) => {
            dataRow.push(subElement.Value)
          })
          this.searchResults.tableRows.push({
            Values: dataRow,
            ValueType: 'String',
          })
        })
      } else {
        this.showGrid = false
      }
    },
    async runAIP() {
      const aip = this.$refs.aip

      let validData = true
      if (aip.radio === 'send-to-folder' && (!aip.successFolderID || !aip.failureFolderID)) validData = false
      if (aip.radio === 'send-to-workflow' && !aip.selectedWorkflow.iID) validData = false
      if (!aip.selectedAIPIDs || aip.selectedAIPIDs.length === 0) validData = false
      aip.errorSuccessFolder = !aip.successFolderID
      aip.errorFailureFolder = !aip.failureFolderID
      aip.errorWorkflow = !aip.selectedWorkflow.iID
      aip.errorAIPs = !aip.selectedAIPIDs || aip.selectedAIPIDs.length === 0
      if (!validData) return

      const payload = {
        documentIDs: this.checkedDocuments,
        aipIDs: aip.selectedAIPIDs,
        successFolderID: aip.radio === 'send-to-folder' ? aip.successFolderID : null,
        failureFolderID: aip.radio === 'send-to-folder' ? aip.failureFolderID : null,
        workflowID: aip.radio === 'send-to-workflow' ? aip.selectedWorkflow.iID : null,
      }
      aip.isLoading = true
      const result = await this.$store.dispatch('document/saveBulkProcessActionAIP', payload)
      aip.isLoading = false

      if (result.Value === 'True') {
        this.snackbarTitle = 'AIP Created'
        this.snackbarSubTitle = 'Advanced Image Process successfully created!'
        this.snackbarImage = true
        this.showSnackbarBool = true
        this.showAIPDialog = false
        this.refreshResults()
      } else {
        this.snackbarTitle = 'Error Creating AIP'
        this.snackbarSubTitle = 'There was an error creating your Advanced Image Process.\nPlease contact your administrator if the problem persists.'
        this.snackbarImage = false
        this.showSnackbarBool = true
      }
    },
    async saveSearchCriteria() {
      if (this.activeSearch.Type === 161) {
        this.$refs.fullTextSearch.search()
      } else {
        this.$refs.indexSearch.saveSearchCriteria()
      }
      this.toggleActiveSearchDisplay()
    },
    async sendDocBarcodeRecognition() {
      const payload = this.$refs.barcodeRecognition.buildPayload()
      payload.documentIDs = this.checkedDocuments

      const response = await this.$store.dispatch('document/saveDocumentsForBarcodeRecognition', payload)
      this.toggleBarcodeRecognition()
      this.checkedDocuments = []

      if (response?.Value === 'True') {
        this.showSnackbarBool = true
        this.snackbarTitle = 'Success'
        this.snackbarSubTitle = 'An email will be sent when the barcode process has completed.'
        this.snackbarImage = true
      } else {
         this.showSnackbarBool = true
        this.snackbarTitle = 'Error'
        this.message = 'Something went wrong, please contact admin'
        this.snackbarImage = false
      }
      this.refreshResults()
    },
    setCheckedDocuments(newList) {
      this.checkedDocuments = newList.map(item => {
        return item.Values[0]
      })
      const checkedDocsForMerge = newList.map(item => {
        return { id: item.Values[0], name: item.Values[3] }
      })
        this.$store.dispatch('document/setCheckedDocuments', checkedDocsForMerge)
    },
    async setTypeSearch(type) {
      if (type === 'reset') {
        this.showActiveSearch = true
      } else if (type === 'change') {
        await this.$store.dispatch('mainViews/toggleSavedSearchDropDown', true)
      }
    },
    showEmailSnackbar(value) {
      const [response] = value
      this.snackbarTitle = response ? 'Email successfully sent!' : 'Unable to send email';
      this.snackbarSubTitle = response ? 'Thank you for using Vasion\'s email service' : 'Please try again or contact your administrator';
      this.snackbarImage = response
      this.showSnackbarBool = true;
    },
    showSelectionSnackbar() {
      this.snackbarTitle = 'Nothing Selected!'
      this.snackbarSubTitle = 'Please make a selection before performing an action.'
      this.snackbarImage = false
      this.showSnackbarBool = true;
    },
    async showRefineSearch() {
      await this.$store.dispatch('search/setRefineSearch', true)
      this.showActiveSearch = true
    },
    showSnackbar(messageObject) {
      this[messageObject.modalToClose] = false
      this.snackbarTitle = messageObject.title
      this.snackbarSubTitle = messageObject.subTitle
      this.snackbarImage = messageObject.value
      this.showSnackbarBool = true
    },
    async startWorkflowMenuClick() {
      if (!this.areThereCheckedDocuments) {
        return
      }

      this.workflowList = await this.$store.dispatch('workflow/getWorkflowMenuItems')
      this.userAssignPayload = null
      this.workflowUIType = 'wfSelection'
      this.selectedWorkflowID = 0
      this.showStartWorkflow = true
    },
    toggleActiveSearchDisplay() {
      this.showActiveSearch = !this.showActiveSearch
      if (!this.showActiveSearch) {
        this.$store.dispatch('search/setRefineSearch', false)
      }
    },
    toggleAIP() { this.showAIPDialog = !this.showAIPDialog },
    toggleBarcodeRecognition() { this.showBarcodeDialog = !this.showBarcodeDialog },
    toggleDownloadDocument() { this.showDownloadModal = !this.showDownloadModal },
    toggleMergeAdd() {
      this.showMergeDialog = !this.showMergeDialog
      this.showMergeAddDialog = !this.showMergeAddDialog
    },
    toggleMergeBrowse() {
      this.showMergeDialog = !this.showMergeDialog
      this.showMergeBrowseDialog = !this.showMergeBrowseDialog
      this.mergeBrowseDialogSelectionMade = false
    },
    toggleOcr() { this.showOcrDialog = !this.showOcrDialog },
    togglePrintDocument() { this.showPrintModal = !this.showPrintModal },
    async tryStartWorkflow() {
      // try submitting the first document to see if we need to do any prompting
      this.isWFLoading = true
      if (!this.userAssignPayload) {
         const userIdInt = parseInt(this.userID, 10)
        this.userAssignPayload = {
            documentIDList: this.checkedDocuments.filter(a => a),
            workflowId: this.selectedWorkflowID,
            userId: userIdInt,
        }
      }
      const responsePayload = await this.$store.dispatch('workflow/attemptAssignToWorkflowWithData', this.userAssignPayload)
      if (responsePayload) {
        if (responsePayload.assignUserGroupFilterId > 0) {
          // need to prompt the user to choose an approver from a group
          this.assignUserGroupFilterId = responsePayload.assignUserGroupFilterId
          this.userAssignPayload = responsePayload.userAssignPayload
          this.workflowUIType = 'selectUser'
        } else {
          // unable to send the document to workflow
          this.snackbarTitle = 'Workflow Failure'
          this.snackbarSubTitle = 'Unable to add one or more Documents to Workflow.'
          this.snackbarImage = false
          this.showStartWorkflow = false
          this.showSnackbarBool = true
        }
        this.isWFLoading = false
        return
      }

      // the first document was added and a prompt was not needed, so do the rest!
      const promiseArray = this.checkedDocuments.map((documentID, index) => {
        if (index === 0) {
          return null
        }

        this.userAssignPayload.documentId = documentID
        return this.$store.dispatch('workflow/attemptAssignToWorkflowWithData', this.userAssignPayload)
      })

      // start the remaining workflows and check the results
      const resultArray = await Promise.all(promiseArray)
      let success = true

      if (resultArray && resultArray.length > 0) {
        resultArray.map((element) => {
          if (element !== null) {
            success = false
          }

          return true
        })
      }

      this.snackbarTitle = success ? 'Success' : 'Workflow Failure'
      this.snackbarSubTitle = success ? 'Workflow Started Successfully!' : 'Unable to add one or more Documents to Workflow.'
      this.snackbarImage = success
      this.refreshResults()
      this.showStartWorkflow = false
      this.showSnackbarBool = true
      this.isWFLoading = false
    },
    async updateCheckedDocumentsAreTiffOrPDF() {
      const promiseArr = []
      this.checkedDocuments.forEach(docId => {
        const payload = { documentID: docId, fileExtensions: ['pdf', 'tif', 'tiff'] }
        promiseArr.push(this.$store.dispatch('document/checkFileExtension', payload))
      })
      const promises = await Promise.all(promiseArr)

      this.checkedDocumentsAreTiffOrPDF = !promises.includes('False')
    },
    updateOcrAllPages(value) { this.ocrAllPages = value },
    async viewHitsSearch() {
      const payload = {
        searchGUID: this.searchGUID,
        docID: this.checkedDocumentsForViewHits[0].id,
        versionID: this.checkedDocumentsForViewHits[0].vNumber,
      }
      this.viewHitsResponse = await this.$store.dispatch('search/getFTSearchDocDetails', payload)
      this.showViewHits = !this.showViewHits
    },
    workflowSelected(workflowID) {
      this.selectedWorkflowID = workflowID

      if (this.selectedWorkflowID <= 0) {
        this.showStartWorkflow = false
        return
      }

      this.tryStartWorkflow()
    },
    workflowSelectUserOKClick(payload) {
      if (!payload || !payload.username || !payload.value) {
        this.showStartWorkflow = false
        return
      }
      this.userAssignPayload.documentIDList = this.checkedDocuments.filter(a => a)
      this.userAssignPayload.promptData.prmt_ChooseFromGroup.cfg_UserID = payload.value
      this.tryStartWorkflow()
    },
    yesConfirmationClick() {
      this.showConfirmationDialog = false

      switch (this.confirmationType) {
        case 'Delete':
          this.deleteSelectedDocuments()
          break;
        case 'LegalHold':
          this.legalHoldSelectedDocuments()
          break;
        default:
          break
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  .folderDialogBrowseDiv {
    height: 50vh;
    width: 25vw;
    overflow: auto;
  }
  .mainDiv {
    background-color: $white;
  }

  .innerDiv {
    padding: 7px 16px 7px 16px;
  }

  .toolbarAlign {
    padding-top: 20px;
    padding-bottom: 10px;
    display: flex;
  }

  .resultsGridDiv {
    height: calc(100vh - 190px);
    overflow: auto;
  }

  .smallText {
    height: 28px;
    line-height: 20px;
    min-height: 20px;
    vertical-align: center;
    font-size: 14px;
    padding: 0 0;
    margin-top: 5px;
  }

   .moveUp {
    position: relative;
    top: -20px;
  }

  .lowerLocation {
    margin-top: 17px;
  }

  .w60 {
    width: 86px;
    height: 30px;
  }

  .centerText {
    text-align: center;
  }

  .w75 {
    width: 75px;
    max-height: 30px;
    padding-right: 5px;
  }

  .email-modal-div {
    overflow: auto;
  }
  .search-icon-buttons > * {
    padding: 0px 5px;
  }

  @media(max-width: $phone){
    .toolbarAlign {
      flex-direction: column;
      padding-bottom: 2px;
    }
  }

  .nav-controls{
    display: flex;
  }

  .vasion-toolbar-divider {
    margin-left: 10px;
    margin-right: 5px;
  }

  #toolbar-search-buttons {
    padding-left: 5px;
    color: black;
  }

  .modal-container {
    max-height: 90%;
  }

  .search-form-container {
    max-height: 80%;
    overflow-y: auto;
    padding-right: 25px;
  }

  .temp-drop-down-class {
    max-height: 90%;
    overflow-y: auto;
    padding-right: 50px;
  }

  .button-container {
    float: right;
    padding-top: 25px;
  }

  #search-table th {
    padding-left: 12px;
  }
</style>

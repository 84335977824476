<template>
  <div class="view-hits-container">
    <div class="md-layout header">
      <div class="title">
        View Hits
      </div>
      <VasionButton
        :icon="'VasionExIcon'"
        @vasionButtonClicked="close()"
      />
    </div>
    <div class="tabs">
      <div class="tab-buttons">
        <VasionButton id="tab-content" :classProp="calculatedClass(0)" @vasionButtonClicked="currentTabNumber = 0">
          Content
        </VasionButton>
        <VasionButton id="tab-fields" :classProp="calculatedClass(1)" @vasionButtonClicked="currentTabNumber = 1">
          Fields
        </VasionButton>
        <VasionButton id="tab-notes" :classProp="calculatedClass(2)" @vasionButtonClicked="currentTabNumber = 2">
          Notes
        </VasionButton>
        <VasionButton id="tab-workflowNotes" :classProp="calculatedClass(3)" @vasionButtonClicked="currentTabNumber = 3">
          Workflow Notes
        </VasionButton>
      </div>
      <div class="hits-buttons">
        <VasionButton id="previous-hit" :icon="'VasionChevronLeft'" @vasionButtonClicked="previousHit()" />
        <VasionButton id="next-hit" :icon="'VasionChevronRight'" @vasionButtonClicked="nextHit()" />
        <VasionButton
          id="tab-workflowNotes"
          :isDisabled="currentTabNumber !== 0"
          :classProp="'primary-light'"
          @vasionButtonClicked="goToPage()"
        >
          Go to Page
        </VasionButton>
      </div>
    </div>
    <div v-show="showWarning" class="warning-text">
      The selected word does belong to a page in the document
    </div>
    <div class="view-hits-textarea">
      <!-- eslint-disable vue/no-v-html -->
      <div
        v-if="currentTabNumber === 0"
        id="content-tab"
        class="tab"
        v-html="colorHitWordRed(data.sText)"
      />
      <div
        v-if="currentTabNumber === 1"
        id="fields-tab"
        class="tab"
        v-html="colorHitWordRed(data.sIndexText)"
      />
      <div
        v-if="currentTabNumber === 2"
        id="notes-tab"
        class="tab"
        v-html="colorHitWordRed(data.sNoteText)"
      />
      <div
        v-if="currentTabNumber === 3"
        id="workflowNotes-tab"
        class="tab"
        v-html="colorHitWordRed(data.sWFNoteText)"
      />
      <!-- eslint-enable vue/no-v-html -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'ViewHits',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      currentSelectedHit: -1,
      currentTabNumber: 0,
      hitSize: [],
      previousWordCount: 0,
      showWarning: false,
    }
  },
  computed: {
    contentHitWordCount() { return this.data.sText.split(new RegExp(this.hitWords.concat('|<[^>]*>'), 'gi')).length - 1 },
    fieldsHitWordCount() { return this.data.sIndexText.split(new RegExp(this.hitWords.concat('|<[^>]*>'), 'gi')).length - 1 },
    hitWords() {
      return this.data.oHitList.filter(e => !!e).join('|')
    },
    notesHitWordCount() { return this.data.sNoteText.toLowerCase().trim().split(new RegExp(this.hitWords.concat('|<[^>]*>'), 'gi')).length - 1 },
    workflowNotesHitWordCount() { return this.data.sWFNoteText.toLowerCase().split(new RegExp(this.hitWords.concat('|<[^>]*>'), 'gi')).length - 1 },
  },
  watch: {
    currentTabNumber: function () {
      this.showWarning = false
      this.currentSelectedHit = -1
      this.currentTabNumber = this.currentTabNumber === -1 ? 3 : this.currentTabNumber
      this.currentTabNumber = this.currentTabNumber === 4 ? 0 : this.currentTabNumber
      switch (this.currentTabNumber) {
        case 0:
          this.previousWordCount = this.contentHitWordCount
          break;
        case 1:
          this.previousWordCount = this.fieldsHitWordCount
          break;
        case 2:
          this.previousWordCount = this.notesHitWordCount
          break;
        case 3:
          this.previousWordCount = this.workflowNotesHitWordCount
          break;
        default:
          break;
      }
    },
  },
  methods: {
    calculatedClass(selectedTab) {
      return this.currentTabNumber === selectedTab ? 'text-selected' : 'text'
    },
    close() {
      this.$emit('close')
    },
    colorHitWordRed(data) {
      if (this.data.oHitList.length === 0 || !this.data.oHitList) {
        return data
      }
      let n = -1
      const newLine = /\n/g;

      const tempResult = data.replace(new RegExp(this.hitWords.concat('|<[^>]*>'), 'gi'), match => {
        n += 1
        return `<span id="hit${n}.0" style="color: red">${match}</span>`
      })

      return tempResult.replace(newLine, '<br />')
    },
    findIndexes(exp) {
      const regEx = new RegExp(exp, 'gi')
      const returnArray = []
      while ((regEx.exec(this.data.sText))) {
        returnArray.push(regEx.lastIndex)
      }
      return returnArray
    },
    goToPage() {
      let startingPageNumber = 1
      if (this.currentTabNumber === 0 && this.currentSelectedHit !== -1) {
        const pageEndIndexes = this.findIndexes(/_PAGEEND_/)
        const hitWordIndexes = this.findIndexes(this.hitWords.concat('|<[^>]*>'))
        pageEndIndexes.forEach(pageEnd => {
          if (hitWordIndexes[this.currentSelectedHit] > pageEnd && hitWordIndexes[this.currentSelectedHit] < pageEndIndexes[pageEndIndexes.length - 1]) {
            startingPageNumber += 1
          } else if (hitWordIndexes[this.currentSelectedHit] > pageEndIndexes[pageEndIndexes.length - 1]) {
            startingPageNumber = 0
          }
        });
        if (startingPageNumber !== 0) {
          this.$store.dispatch('document/setBackRoute', '/searchResults')
          this.$router.push({ name: 'DocumentWithStartingPage', params: { documentID: this.data.iDocumentId.toString(), startingPage: startingPageNumber } })
        } else {
          this.showWarning = true
        }
      }
    },
    highlightHit(id) {
      if (document.getElementById(`hit${id}.0`) !== null) {
        document.getElementById(`hit${id}.0`).setAttribute('style', 'background-color: #2A52BE; color: #FFFFFF')
        this.$nextTick(() => document.getElementById(`hit${id}.0`).scrollIntoView())
      }
    },
    previousHit() {
      this.showWarning = false
      if (document.getElementById(`hit${this.currentSelectedHit}.0`) !== null) {
        this.unHighlightHit(this.currentSelectedHit)
      }
      this.currentSelectedHit -= 1
      if (document.getElementById(`hit${this.currentSelectedHit}.0`) !== null) {
        this.highlightHit(this.currentSelectedHit)
      } else {
        this.currentTabNumber -= 1
        this.$nextTick(() => {
          this.currentSelectedHit = this.previousWordCount - 1
          this.highlightHit(this.previousWordCount - 1)
        })
      }
    },
    async nextHit() {
      this.showWarning = false
      if (document.getElementById(`hit${this.currentSelectedHit}.0`) !== null) {
        this.unHighlightHit(this.currentSelectedHit)
      }
      this.currentSelectedHit += 1
      if (document.getElementById(`hit${this.currentSelectedHit}.0`) !== null) {
        this.highlightHit(this.currentSelectedHit)
      } else {
        this.currentTabNumber += 1
        this.$nextTick(() => {
            this.currentSelectedHit = 0
            this.highlightHit(this.currentSelectedHit)
        })
      }
    },
    unHighlightHit(id) {
      document.getElementById(`hit${id}.0`).style.backgroundColor = null
      document.getElementById(`hit${id}.0`).style.color = 'red'
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.black {
  color: black;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.hits-buttons {
  display: flex;
  align-items: center;
}
.tabs {
  padding: 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.view-hits-container {
  width: 928px;
  height: 592px;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  margin: auto;
}
.title {
  @include SubtitleActive;
  font-size: 1.75em;
}
.view-hits-textarea {
    width: 100%;
    height: 100%;
    overflow: auto;
    border-radius: 8px;
    padding: 10px;
    border: solid 1px $grey-200;
  }
.highlightRed {
  color: black;
  background-color: red;
}
.warning-text {
  color: red;
}

</style>
